import BadgeWidgets from '~/themes/base/components/shop/BadgeWidgets'

export default {
  extends: BadgeWidgets,
  data() {
    const _this = this

    return {
      badges: [
        {
          name: 'heureka',
          html: `<div id="showHeurekaBadgeHere-11"></div>`,
          script() {
            if (_this.isTabletOrMobileDetected) {
              this.html = `
                 <a
                   href="https://obchody.heureka.cz/sex-shop-cz/recenze/"
                   target="_blank"
                   title="Heureka.cz - ověřené hodnocení obchodu SEX-SHOP.CZ"
                 >
                   <img
                     alt="Heureka.cz - ověřené hodnocení obchodu SEX-SHOP.CZ"
                     height="105"
                     width="105"
                     src="https://im9.cz/cb/35230-3.png"
                     style="border: 0;"
                   >
                 </a>
                `
            } else {
              const HEUREKA_PUBLIC_KEY = '8DDBA6B454F93E1968D8AAED1FDBE608'
              const s = document.createElement('script')

              s.setAttribute('type', 'text/javascript')
              s.setAttribute('async', 'true')
              s.setAttribute('src', 'https://cz.im9.cz/direct/i/gjs.php?n=wdgt&sak=' + HEUREKA_PUBLIC_KEY)
              s.addEventListener('load', () => {
                window._hwq.push(['setKey', HEUREKA_PUBLIC_KEY])
                window._hwq.push(['showWidget', '11', '35230', 'SEX-SHOP.CZ', 'sex-shop-cz'])
              })

              document.head.appendChild(s)
            }
          },
        },
        {
          name: 'retino',
          html: `<div class="retino-badge-container-icon" data-company="2e151749-2e8b-40f0-9416-3443f87fe1b5"></div>`,
          script() {
            window.RetinoBadgeQueue = [
              {
                companyId: '2e151749-2e8b-40f0-9416-3443f87fe1b5',
                type: 'icon',
                staticUrl: 'https://cdn.retino.com/',
                mediaUrl: 'https://cdn.retino.com/media/',
                language: 'cs',
              },
            ]

            let n = document.createElement('script')
            let o = document.querySelector('script')

            n.src = 'https://cdn.retino.com/badgeInjector.js'
            n.async = true
            o.parentNode.insertBefore(n, o)
          },
        },
      ],
    }
  },
}
